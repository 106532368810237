exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "career":
      return `/career/${doc.uid}`;

    case "services":
      return `/services/${doc.uid}`

    default:
      if (!doc.uid) return "/";
      return doc.uid;
  }
};
